<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner flex-col flex">
      <div class="content flex-col flex pt-4">
        <slot name="message">
          <h2>
            <span class="red_green">
                Vehicle create! Want to create a new one?
            </span>
          </h2>
        </slot>

        <slot name="button">
          <div class="btn_line">
            <button v-if="!only_button" class="btn delete_btn" @click="continueCreate">Continue</button>
            <button v-if="!only_button" class="btn close_btn" @click="closeAndGoVehicles">Close</button>

            <button v-if="only_button" class="btn ok_btn py-3 bg-gray-200 border border-gray-300" @click="closeModal">OK</button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessModal",
  props: {
    only_button: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('close-success-modal');
      this.show = false
    },
    closeAndGoVehicles() {
      this.$emit('return-to-vehicles');
      this.closeModal();
    },
    continueCreate() {
      this.$emit('continue-create');
      this.closeModal();
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 546px;
  width: 50vw;
  margin: auto;
  height: 303px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  width: 100px;
  height: 40px;
  margin: 0 20px;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.75;
}
.inner .btn:hover {
  opacity: 1;
}
.red_green {
  color: #28a745;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.btn_line {
  margin-top: 50px;
}
.delete_btn {
  background-color: #28a745;
}
.close_btn {
  background-color: #3d4852;
}
.ok_btn {
  color: #22292f !important;
}
</style>
