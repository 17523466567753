<template>
  <div class="page customers">
    <div class="roll_back_line flex justify-between">
      <div @click="goBack" class="inline-block flex w-1/3 justify-start items-center go_back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 18L8 12L14 6" stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div class="h-full label_roll_back">
          Back
        </div>
      </div>
    </div>

    <div class="title_line flex justify-between">
      <div class="inline-block flex w-1/3 justify-start items-center">
        <h1 class="text-left">Account</h1>
      </div>
    </div>

    <div class="form_body flex justify-between">
      <div class="w-1/5">
        <div
            class="form_label text-left py-2"
            v-for="(section, index) in sections"
            :key="'section-number-' + index">
          <router-link
              :class="{'active': $route.path === section.link}"
              class="py-2 px-4"
              :to="section.link">{{ section.name }}</router-link>
        </div>
      </div>

      <div class="w-4/5 block">
        <router-view/>
      </div>
    </div>


    <SuccessModal
        v-on:continue-create="continueCreate"
        v-on:return-to-vehicles="cancelForm"
        ref="success_modal"></SuccessModal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import SuccessModal from "@/components/SuccessModal";
export default {
  name: "Account",
  data() {
    return {
      sections: {
        profile: {
          name: 'Profile',
          section: 'profile',
          link: '/account/profile',
          active: false,
        },
        change_password: {
          name: 'Change password',
          section: 'change_password',
          link: '/account/change_password',
          active: false
        },
        billing: {
          name: 'Billing',
          section: 'billing',
          link: '/account/billing',
          active: false
        },
        addons: {
          name: 'Addons',
          section: 'addons',
          link: '/account/addons',
          active: false
        },
        usage: {
          name: 'Usage',
          section: 'usage',
          link: '/account/usage',
          active: false
        },
        routing: {
          name: 'Routing',
          section: 'routing',
          link: '/account/routing',
          active: false
        }
      },
    }
  },
  methods: {
    ...mapMutations([]),
    async goBack() {
      this.$router.go(-1);
    },
    openSuccessModal() {
      this.$refs.success_modal.show = true;
    },
    continueCreate() {
      this.skipForm();
    },
    cancelForm() {
      this.$router.push('/vehicles');
    },
  },
  async mounted() {},
  created() {},
  computed: {
    ...mapGetters([]),
  },
  components: {
    SuccessModal,
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.item {
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.go_back {
  cursor: pointer;
}
.title_line {
  padding: 10px 15px 22px;
}
.roll_back_line {
  color: #6c757d;
  font-size: 1.17em;
  font-weight: 700;
  padding: 10px 15px;
  width: 95%;
  margin: auto;
}
.label_roll_back {
  padding-top: 3px;
}
.active {
  background: #FFF1CE;
  border-radius: 5px;
  color: #FFA800;
}
.form_body {
  width: 95%;
  padding: 30px 15px;
  margin: auto;
}
.form_label {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 600;
}
.input_label {
  color: #22292f;
  font-size: 1em;
  font-weight: 600;
  span {
    color: #28a745;
  }
}
.span_in_select {
  color: #6c757d !important;
}
.input_span {
  position: relative;
}
.units {
  position: absolute;
  top: 3px;
  right: 15px;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 10px;
  width: 100px;
}
</style>
